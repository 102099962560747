import { CreateAccountSessionState } from './createAccountSession.types'

const initialState: CreateAccountSessionState = {
  loading: false,
  error: null,
  success: null
  // Initialize any additional state properties here
}

export default initialState
