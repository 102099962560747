import React, { useCallback, useEffect, useMemo, useState } from 'react'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, CircularProgress, Grid, Link, SvgIcon, Typography } from '@mui/material'

import TripPreferencesModal from '../../../../shared/components/TripPreferencesModal/TripPreferencesModal.component'
import FilterResults from './FilterResults/FilterResults.component'
import FlightOffer from './FlightOffer/FlightOffer.component'

import images from '../../../../assets/images'
import { getFlightFares } from '../../../../redux/states/flights/getFlightFares/getFlightFares.slice'
import { GetFlightFaresRequestPayload } from '../../../../redux/states/flights/getFlightFares/getFlightFares.types'
import {
  FareDetailsResponse,
  FareOffer
} from '../../../../redux/states/flights/getFlightFares/types/FareDetailsResponse.types'
import { getNextFlights } from '../../../../redux/states/flights/getNextFlights/getNextFlights.slice'
import { SearchFlightsSuccessResponse } from '../../../../redux/states/flights/searchFlights/searchFlights.types'
import { RootState } from '../../../../redux/store/store.types'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import theme from '../../../../shared/styles/themes/default.theme'
import { CabinClass } from '../../../Travel/components/FlightSearchWidget/FlightSearchWidget.types'
import { formatFlightPartialOffers } from '../../FlightSearchResults.functions'
import { FormattedPartialOffers, Route } from '../../types/FormattedSearchResults.types'
import { AirlineOption, FilterOptions } from './FilterResults/FilterResults.types'
import styles from './TripSelector.styles'
import { TripSelectorProps } from './TripSelector.types'

const TripSelector: React.FC<TripSelectorProps> = (props) => {
  const {
    routes: flightRoutes,
    offers,
    onComplete,
    initialBaseOfferId,
    isLoadingInitialFlightSearch,
    searchedValues,
    existingFlightItinerary
  } = props

  const [selectedOffers, setSelectedOffers] = useState<FormattedPartialOffers[]>([])
  const [currentRouteIndex, setCurrentRouteIndex] = useState<number>(selectedOffers.length)
  const [currentOffers, setCurrentOffers] = useState<FormattedPartialOffers[]>(offers)
  const [baseOfferId, setBaseOfferId] = useState<string | undefined>(initialBaseOfferId)
  const [filters, setFilters] = useState<FilterOptions>({ airlines: [], sort: '', stops: '' })
  const [availableAirlines, setAvailableAirlines] = useState<AirlineOption[]>([])
  const [tripPreferencesModalOpen, setTripPreferencesModalOpen] = useState<boolean>(false)
  const [loadingOfferId, setLoadingOfferId] = useState<string>('')
  const [fare, setFare] = useState<FareOffer | null>(null)

  const [history, setHistory] = useState<{
    [key: number]: { route: Route; offers: FormattedPartialOffers[]; selectedOffers: FormattedPartialOffers[] }
  }>([])
  const { success: response, loading: isLoadingNextFlights } = useAppSelector(
    (state: RootState) => state.getNextFlights
  )
  const { loading: isLoadingFareDetails } = useAppSelector((state: RootState) => state.getFlightFares)

  const [expandedOfferId, setExpandedOfferId] = useState<string | null>(null)

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  useEffect(() => {
    setBaseOfferId(initialBaseOfferId)
  }, [initialBaseOfferId])

  useEffect(() => {
    setCurrentOffers(offers)
  }, [offers])

  useEffect(() => {
    if (!response) return

    const { offers } = formatFlightPartialOffers(response as unknown as SearchFlightsSuccessResponse)

    setCurrentOffers(offers)
  }, [response])

  useEffect(() => {
    const airlineMap = new Map<string, number>()

    currentOffers.forEach((offer) => {
      const totalAmount = Number(offer.totalAmount)

      if (!airlineMap.has(offer.airline) || totalAmount < airlineMap.get(offer.airline)!) {
        airlineMap.set(offer.airline, totalAmount)
      }
    })

    const uniqueAirlines = Array.from(airlineMap.entries()).map(([name, lowestPrice]) => ({ name, lowestPrice }))

    setAvailableAirlines(uniqueAirlines)
  }, [setAvailableAirlines, currentOffers])

  const onSelectFlightOffer = useCallback(
    (offer: FormattedPartialOffers) => () => {
      if (!baseOfferId) return
      setHistory((prev) => ({
        ...prev,
        [currentRouteIndex]: { route: flightRoutes[currentRouteIndex], offers: currentOffers, selectedOffers }
      }))

      if (currentRouteIndex < flightRoutes.length - 1) {
        setSelectedOffers((prev) => {
          const offerExists = prev.some((existingOffer) => existingOffer.id === offer.id)

          return offerExists ? prev : [...prev, offer]
        })

        const requestPayload = {
          getNextFlightsRequest: {
            outboundOfferId: baseOfferId,
            selected_partial_offers: [...selectedOffers.map((offer) => offer.id), offer.id]
          },

          onSuccess: () => {
            setCurrentRouteIndex((prev) => prev + 1)
          }
        }

        dispatch(getNextFlights(requestPayload))
      } else {
        setLoadingOfferId(offer.id)

        const payload: GetFlightFaresRequestPayload = {
          request: {
            outboundOfferId: baseOfferId,
            selected_partial_offers: [...selectedOffers.map((offer) => offer.id), offer.id]
          },

          onSuccess: (response: FareDetailsResponse) => {
            const fare = response.offers[0]
            fare.cabinClass = response.cabin_class as CabinClass

            setFare(fare)

            if (!existingFlightItinerary) {
              setTripPreferencesModalOpen(true)
            } else {
              navigate(routes.tripSummary.path, {
                state: {
                  fare,
                  searchedValues,
                  existingFlightItinerary,
                  isBookingFlight: existingFlightItinerary!
                }
              })
            }
          }
        }

        dispatch(getFlightFares(payload))
      }
    },
    [
      dispatch,
      baseOfferId,
      currentRouteIndex,
      flightRoutes,
      selectedOffers,
      currentOffers,
      existingFlightItinerary,
      navigate,
      searchedValues
    ]
  )

  const breadcrumbs = useMemo(() => {
    return flightRoutes.map((route, index) => {
      if (currentRouteIndex > index) {
        return (
          <Link
            underline="hover"
            key={index}
            color="inherit"
            sx={styles.activeBreadcrumb}
            onClick={() => {
              setCurrentRouteIndex(index)
              setCurrentOffers(history[index].offers)
              setSelectedOffers(history[index].selectedOffers)
            }}
          >
            {route?.origin.iata_code} to {route?.destination.iata_code}
          </Link>
        )
      } else {
        return (
          <Link underline={'none'} key={index} color="inherit" sx={styles.inactiveBreadcrumb}>
            {route?.origin.iata_code} to {route?.destination.iata_code}
          </Link>
        )
      }
    })
  }, [flightRoutes, currentRouteIndex, history])

  const HeaderView = useMemo(() => {
    if (flightRoutes.length === 0) return null

    return (
      <Typography variant="h5" sx={styles.title}>
        Choose your flight from {flightRoutes[currentRouteIndex]?.origin.iata_code} to{' '}
        {flightRoutes[currentRouteIndex]?.destination.iata_code}
      </Typography>
    )
  }, [flightRoutes, currentRouteIndex])

  const showLoadingDetails = () => {
    function GradientCircularProgress() {
      return (
        <Box sx={{ position: 'relative' }}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: theme.palette.primary.light,
              opacity: 0.5
            }}
            size={200}
            thickness={1}
            {...props}
            value={100}
          />

          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor={theme.palette.primary.main} />
                <stop offset="100%" stopColor={theme.palette.primary.light} />
              </linearGradient>
            </defs>
          </svg>

          <CircularProgress sx={styles.loadingProgress} size={200} thickness={2} />

          <Box sx={styles.loadingFlightContainer}>
            <SvgIcon component={images.PlaneIcon} sx={styles.loadingFlightIcon} />

            <Typography sx={styles.loadingFlightText}>
              {/* {flightRoutes[currentRouteIndex]?.origin.iata_code} {' to '} {flightRoutes[currentRouteIndex]?.destination.iata_code} */}
            </Typography>
          </Box>
        </Box>
      )
    }

    return (
      <Box sx={styles.loadingDetails}>
        <GradientCircularProgress />

        <Box sx={styles.loadingDetailsContent}>
          <Typography variant="h5" sx={styles.loadingTitle}>
            We are finding your flight!
          </Typography>

          <Typography variant="body2" sx={styles.loadingSubtitle}>
            This may take a few seconds
          </Typography>
        </Box>
      </Box>
    )
  }

  const isLoadingFlights = useMemo(() => {
    return isLoadingInitialFlightSearch || isLoadingNextFlights
  }, [isLoadingInitialFlightSearch, isLoadingNextFlights])

  const filteredOffers = useMemo(() => {
    const filtered = currentOffers.filter((offer) => {
      if (filters.airlines.length > 0 && !filters.airlines.includes(offer.airline)) return false

      const stopCount = offer.slices[0].segments.length - 1

      if (filters.stops === 'Direct only' && stopCount !== 0) return false
      if (filters.stops === '1 stop at most' && stopCount > 1) return false
      if (filters.stops === '2 stops at most' && stopCount > 2) return false

      return true
    })

    switch (filters.sort) {
      case 'Cheapest':
        return filtered.sort((a, b) => parseFloat(a.totalAmount) - parseFloat(b.totalAmount))

      case 'Fastest':
        return filtered.sort((a, b) => {
          const aDuration = a.slices.reduce((acc, slice) => acc + parseInt(slice.totalDuration), 0)
          const bDuration = b.slices.reduce((acc, slice) => acc + parseInt(slice.totalDuration), 0)

          return aDuration - bDuration
        })

      case 'Best':
        // Assuming 'Best' is the default sorting or some custom logic
        return filtered

      default:
        return filtered
    }
  }, [currentOffers, filters])

  const getNumberOfStops = (offer: FareOffer) => {
    return offer.slices.map((slice) => slice.segments.length - 1).reduce((acc, curr) => acc + curr, 0)
  }

  const onSelectTripPreferences = (preferences: { price: number; stops: number }) => {
    if (onComplete) {
      onComplete(fare as FareOffer, preferences)
      setCurrentRouteIndex(currentRouteIndex - 1)
      setCurrentOffers(history[currentRouteIndex - 1].offers)
      setSelectedOffers(history[currentRouteIndex - 1].selectedOffers)
    } else {
      navigate(routes.tripSummary.path, {
        state: {
          fare,
          searchedValues,
          tripPreferences: preferences,
          existingFlightItinerary,
          isBookingFlight: existingFlightItinerary!
        }
      })
    }
  }

  const showMainContent = () => {
    const getBreadcrumbs = () => {
      return (
        <Breadcrumbs
          sx={styles.breadcrumbs}
          separator={<NavigateNextIcon fontSize="small" sx={styles.breadcrumbsSeparator} />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      )
    }

    return (
      <Grid container>
        <Grid sx={styles.tripSelectorContainer} gap={3}>
          <Grid item xs={12} sm={3}>
            <FilterResults setFilters={setFilters} availableAirlines={availableAirlines} />
          </Grid>

          <Grid item xs={12} sm={9}>
            {getBreadcrumbs()}

            {!isLoadingFlights && flightRoutes.length > 0 && (
              <Box sx={styles.header}>
                {HeaderView}

                <Typography variant="body2" sx={styles.subtitle}>
                  Prices are shown in USD currency.
                </Typography>
              </Box>
            )}

            {isLoadingFlights && showLoadingDetails()}

            {!isLoadingFlights && (
              <Box sx={styles.offersContainer}>
                {filteredOffers.map((offer) => (
                  <FlightOffer
                    key={offer.id}
                    onSelect={onSelectFlightOffer(offer)}
                    isLoading={isLoadingFareDetails && loadingOfferId === offer.id}
                    duration={offer.slices[0].totalDuration}
                    stopCount={offer.slices[0].segments.length - 1}
                    airlineLogo={offer.airlineLogo}
                    originAirportName={offer.slices[0]?.origin.iata_code}
                    destinationAirportName={offer.slices[offer.slices.length - 1].destination.iata_code}
                    departureTime={offer.slices[0].segments[0].departure.time}
                    arrivalTime={offer.slices[offer.slices.length - 1].segments[0].arrival.time}
                    cost={`${offer.totalAmount}`}
                    currency={offer.currency}
                    travelClass={offer.travelClass}
                    offer={offer}
                    expanded={expandedOfferId === offer.id}
                    onExpand={() => setExpandedOfferId(expandedOfferId === offer.id ? null : offer.id)}
                  />
                ))}
              </Box>
            )}
          </Grid>
        </Grid>

        <TripPreferencesModal
          open={tripPreferencesModalOpen}
          onSubmit={onSelectTripPreferences}
          onClose={() => setTripPreferencesModalOpen(false)}
          minimumStops={fare ? getNumberOfStops(fare) : 0}
          flightTotalPrice={fare ? parseFloat(fare.total_amount) : 0}
        />
      </Grid>
    )
  }

  return <Box sx={styles.container}>{showMainContent()}</Box>
}

export default TripSelector
