import React, { useMemo } from 'react'

import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'

import { formatDuration } from '../../../../../../shared/functions/Date/date.functions'
import styles from './FlightsDropdown.styles'
import { FlightOption, FlightsDropdownProps } from './FlightsDropdown.types'

const FlightsDropdown: React.FC<FlightsDropdownProps> = ({ fareOffer, value, onChange }) => {
  const flightOptions: FlightOption[] = useMemo(
    () =>
      fareOffer.slices.flatMap((slice) =>
        slice.segments.map((segment) => ({
          id: segment.id,
          label: `${slice.origin.city_name} (${slice.origin.iata_code}) → ${slice.destination.city_name} (${slice.destination.iata_code})`,
          duration: formatDuration(segment.duration),
          segmentId: segment.id
        }))
      ),
    [fareOffer]
  )

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value as string)
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h6" gutterBottom>
        Flights
      </Typography>
      <Select value={value} onChange={handleChange} sx={styles.select} displayEmpty>
        {flightOptions.map((option) => (
          <MenuItem key={option.id} value={option.segmentId} sx={styles.menuItem}>
            <Typography sx={styles.route}>{option.label}</Typography>
            <Typography sx={styles.duration}>Duration: {option.duration}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default FlightsDropdown
