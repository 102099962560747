import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    maxHeight: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius / 4,
    gap: theme.spacing(2),
    textAlign: 'center',
    overflow: 'auto'
  } as SxProps,

  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(0)
  } as SxProps,

  title: {
    fontWeight: theme.typography.fontWeightBold
  } as SxProps,

  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5)
  } as SxProps,

  shareHourrierLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    cursor: 'pointer'
  } as CSSProperties,

  shareHourrierLinkContainer: {
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '20px'
  } as SxProps,

  shareViaSocialMedia: {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center'
  } as SxProps,

  copyLinkInstructionsText: {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium
  } as SxProps,

  shareLinkContainer: {
    maxHeight: 'calc(100vh - 200px)',
    padding: theme.spacing(0, 0)
  } as SxProps,

  shareLinkTop: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center'
  } as SxProps,

  shareLinkModalImage: {
    height: '280px',
    marginBottom: theme.spacing(2)
  } as CSSProperties,

  shareLinkModalPrimaryText: {
    fontWeight: theme.typography.fontWeightBold
  } as SxProps,

  shareLinkModalSecondaryText: {
    textAlign: 'center'
  } as SxProps,

  shareLinkModalTertiaryText: {
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  } as SxProps,

  linkText: {
    color: theme.palette.primary.main
  } as CSSProperties,

  shareViaSocialMediaContainer: {
    textAlign: 'center'
  } as SxProps,

  socialIconsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: theme.spacing(1),
    margin: theme.spacing(2, 0)
  } as SxProps,

  socialIconButton: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0),
    width: '48px',
    height: '48px'
  },

  socialMediaIcon: {
    width: '100%',
    height: '100%'
  } as CSSProperties,

  copyLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },

  copyLinkContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius / 4,
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  } as SxProps,

  copyLinkText: {
    color: theme.palette.grey[500],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '70%',
    border: 'none',
    boxShadow: 'none',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none'
      }
    }
  } as SxProps,

  // copyButton: {
  //   height: '100%',
  //   textTransform: 'none',
  //   padding: theme.spacing(1, 2)
  // } as SxProps,

  divider: {
    margin: theme.spacing(2, 0)
  } as SxProps,

  closeButtonContainer: {
    position: 'absolute',
    width: '100%',
    bottom: theme.spacing(2),
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2)
  } as SxProps,

  closeButton: {
    alignSelf: 'center',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none'
  } as SxProps
}

export default styles
