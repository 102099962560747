import React, { useEffect, useMemo, useState } from 'react'

import { Box, Typography } from '@mui/material'

import Button from '../Button/Button.component'
import Modal from '../Modal/Modal.component'

import styles from './TripPreferencesModal.styles'
import { TripPreferencesModalOption, TripPreferencesModalProps } from './TripPreferencesModal.types'

const TripPreferencesModal: React.FC<TripPreferencesModalProps> = (props) => {
  const { open, onClose, onSubmit, minimumStops, flightTotalPrice } = props

  const priceOptions: TripPreferencesModalOption[] = [
    { label: '+$20', value: 20 },
    { label: '+$40', value: 40 },
    { label: '+$50', value: 50 }
  ]

  const stopOptions: TripPreferencesModalOption[] = useMemo(
    () => [
      { label: 'Non Stop', value: 0 },
      { label: '1 Stop', value: 1 },
      { label: '+2 Stops', value: 2 }
    ],
    []
  )

  const defaultStopOption = useMemo(() => {
    return stopOptions.find((option) => option.value >= minimumStops) || stopOptions[stopOptions.length - 1]
  }, [minimumStops, stopOptions])

  const [selectedPrice, setSelectedPrice] = useState(priceOptions[0])
  const [selectedStops, setSelectedStops] = useState(defaultStopOption)

  useEffect(() => {
    setSelectedStops(defaultStopOption)
  }, [defaultStopOption])

  const handlePriceSelection = (option: TripPreferencesModalOption) => {
    setSelectedPrice(option)
  }

  const handleStopsSelection = (option: TripPreferencesModalOption) => {
    setSelectedStops(option)
  }

  const handleSetPreferences = () => {
    onSubmit({ price: selectedPrice.value, stops: selectedStops.value })
    onClose()
  }

  const modalBody = (
    <Box sx={styles.modalBody}>
      <Box sx={styles.priceContainer}>
        <Box sx={styles.currentPriceBox}>
          <Typography variant="subtitle2">Current Price</Typography>

          <Typography variant="h4" sx={styles.priceText}>
            ${flightTotalPrice.toFixed(2)}
          </Typography>
        </Box>

        <Box sx={styles.saveBox}>
          <Typography variant="body1" sx={styles.saveText}>
            Get Item Requests! Fly for Less!
          </Typography>

          <Typography variant="body2">After you set preferences, go to Share Link to get item requests.</Typography>
        </Box>
      </Box>

      <Typography variant="h6" sx={styles.sectionTitle}>
        Price
      </Typography>

      <Box sx={styles.optionsContainer}>
        {priceOptions.map((option) => (
          <Button
            key={option.value}
            buttonType="secondary"
            variant="outlined"
            text={option.label}
            containerStyle={styles.optionButtonContainer}
            sx={{
              ...styles.optionButton,
              ...(selectedPrice.value === option.value ? styles.selectedOption : {})
            }}
            onClick={() => handlePriceSelection(option)}
          />
        ))}
      </Box>

      <Typography variant="h6" sx={styles.sectionTitle}>
        Stops
      </Typography>

      <Box sx={styles.optionsContainer}>
        {stopOptions.map((option) => (
          <Button
            key={option.value}
            buttonType="secondary"
            variant="outlined"
            text={option.label}
            containerStyle={styles.optionButtonContainer}
            sx={{
              ...styles.optionButton,
              ...(selectedStops.value === option.value ? styles.selectedOption : {})
            }}
            onClick={() => handleStopsSelection(option)}
            disabled={option.value < minimumStops}
          />
        ))}
      </Box>
    </Box>
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Trip Preferences"
      subtitle="Please select your flight preferences. Just in case the price or flight becomes unavailable, we'll offer you a similar flight."
      body={modalBody}
      primaryButton={{
        label: 'Set Now',
        onClick: handleSetPreferences
      }}
    />
  )
}

export default TripPreferencesModal
