import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import Button from '../../shared/components/Button/Button.component'
import PaymentDetails from '../../shared/components/PaymentDetails/PaymentDetails.component'
import { PaymentSubmittedSuccessfully } from '../../shared/components/PaymentSubmittedSuccessfully/PaymentSubmittedSuccessfully.component'
import Stepper from '../../shared/components/Stepper/Stepper.component'
import TripOverview from '../TripSummary/components/TripOverview/TripOverview.component'
import PassengerDetails from './components/PassengerDetails/PassengerDetails.component'

import { createFlightBooking } from '../../redux/states/flights/createFlightBooking/createFlightBooking.slice'
import {
  CreateFlightBookingPassenger,
  CreateFlightBookingRequest
} from '../../redux/states/flights/createFlightBooking/createFlightBooking.types'
import { resetGetBaggageDetails } from '../../redux/states/flights/getBaggageDetails/getBaggageDetails.slice'
import { getCountriesAndCities } from '../../redux/states/flights/getCountriesAndCities/getCountriesAndCities.slice'
import { CountryAndCities } from '../../redux/states/flights/getCountriesAndCities/getCountriesAndCities.types'
import { FareOffer } from '../../redux/states/flights/getFlightFares/types/FareDetailsResponse.types'
import { resetGetSeatDetails } from '../../redux/states/flights/getSeatDetails/getSeatDetails.slice'
import { createAccountSession } from '../../redux/states/payments/createAccountSession/createAccountSession.slice'
import { CreateAccountSessionRequestPayload } from '../../redux/states/payments/createAccountSession/createAccountSession.types'
import {
  createFlightBookingPaymentIntent,
  resetCreateFlightBookingPaymentIntent
} from '../../redux/states/payments/createFlightBookingPaymentIntent/createFlightBookingPaymentIntent.slice'
import {
  CreateFlightBookingPaymentIntentRequestPayload,
  CreateFlightBookingPaymentIntentSuccessResponse
} from '../../redux/states/payments/createFlightBookingPaymentIntent/createFlightBookingPaymentIntent.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { Step } from '../../shared/components/Stepper/Stepper.types'
import { PaymentFormRef } from '../../shared/components/StripeCheckoutForm/StripeCheckoutForm.types'
import localStorage from '../../shared/functions/LocalStorage/localStorage'
import { sanitizePhoneNumber } from '../../shared/functions/String/string.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import {
  CREATE_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY,
  FARE_OFFER_STORAGE_KEY,
  FLIGHT_BOOKING_SUBMISSION_STORAGE_KEY
} from './FlightBooking.dictionary'
import styles from './FlightBooking.styles'
import { PassengerDetailsValues } from './components/PassengerDetails/PassengerDetails.types'
import { BaggageSelections } from './components/PassengerDetails/components/BaggageDetails/components/BaggageDetailsModal/BaggageDetailsModal.types'
import { SeatSelections } from './components/PassengerDetails/components/SeatDetails/components/SeatDetailsModal/SeatDetailsModal.types'

const FlightBooking: React.FC = () => {
  const paymentIntent = router.getUrlParam('payment_intent')
  const redirectStatusFromPayment = router.getUrlParam('redirect_status')
  const paymentSuccess = redirectStatusFromPayment === 'succeeded' && paymentIntent !== null

  const [activeStep, setActiveStep] = useState(paymentSuccess ? 3 : 1) // Start from step 2 (index 1)
  const [passengerDetails, setPassengerDetails] = useState<PassengerDetailsValues[] | null>(null)
  const [isPassengerDetailsFormValid, setIsPassengerDetailsFormValid] = useState(false)
  const [isPaymentFormValid, setIsPaymentFormValid] = useState(false)
  const [isOnboadingComlpete, setIsOnboadingComplete] = useState(false)
  const [paymentRequestLoading, setPaymentRequestLoading] = useState(false)
  const [setupPaymentLoading, setSetupPaymentLoading] = useState(false)
  // const [orderSubmission, setOrderSubmission] = useState<CreateFlightBookingRequest | null>(null)

  const { loading: createFlightBookingPaymentIntentLoading, success: createFlightBookingPaymentIntentSuccess } =
    useAppSelector((state: RootState) => state.createFlightBookingPaymentIntent)

  const { loading: createAccountSessionLoading } = useAppSelector((state: RootState) => state.createAccountSession)

  const {
    loading: createFlightBookingLoading,
    success: createFlightBookingSuccess,
    error: createFlightBookingError
  } = useAppSelector((state: RootState) => state.createFlightBooking)

  const countriesAndCities = useAppSelector((state: RootState) => state.getCountriesAndCities.success)
  const userProfile = useAppSelector((state: RootState) => state.getProfile.success?.data)

  const dispatch = useAppDispatch()
  const navigationProps = router.getNavigationProps()
  const navigate = router.navigate()

  const paymentFormRef = useRef<PaymentFormRef>(null)

  const [baggageSelections, setBaggageSelections] = useState<BaggageSelections>({})
  const [seatSelections, setSeatSelections] = useState<SeatSelections>({})

  const fareOffer = useMemo(() => {
    return (navigationProps?.state?.fareOffer as FareOffer) || localStorage.getItem(FARE_OFFER_STORAGE_KEY)
  }, [navigationProps])

  const flightItineraryId = navigationProps?.state?.flightItineraryId

  const setupIntent = router.getUrlParam('setup_intent')
  const redirectStatusFromSetup = router.getUrlParam('redirect_status')
  const setupPaymentDetailsSuccess = redirectStatusFromSetup === 'succeeded' && setupIntent !== null

  const flightBookingPaymentIntent: CreateFlightBookingPaymentIntentSuccessResponse | null = useMemo(() => {
    return (
      createFlightBookingPaymentIntentSuccess || localStorage.getItem(CREATE_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY)
    )
  }, [createFlightBookingPaymentIntentSuccess])

  // const isAccountSessionExpired = useMemo(() => {
  //   return date(flightBookingPaymentIntent?.data?.accountSession?.expiresAt).isAfter(date().currentDate)
  // }, [flightBookingPaymentIntent])

  useEffect(() => {
    return () => {
      dispatch(resetGetBaggageDetails())
      dispatch(resetGetSeatDetails())

      localStorage.removeItem(FLIGHT_BOOKING_SUBMISSION_STORAGE_KEY)
      localStorage.removeItem(FARE_OFFER_STORAGE_KEY)
      localStorage.removeItem(CREATE_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY)
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getCountriesAndCities({}))
    window.scrollTo(0, 0)
  }, [dispatch])

  const isTransfer = useMemo(() => {
    return !!flightBookingPaymentIntent?.data.accountSession?.secret
  }, [flightBookingPaymentIntent])

  const handleSubmitOrder = useCallback(() => {
    const order: CreateFlightBookingRequest | null = localStorage.getItem(FLIGHT_BOOKING_SUBMISSION_STORAGE_KEY)

    // if (order) {
    //   setOrderSubmission(order)
    // }

    if (order) {
      const onSuccess = () => {
        dispatch(resetCreateFlightBookingPaymentIntent())
        localStorage.removeItem(FLIGHT_BOOKING_SUBMISSION_STORAGE_KEY)
        localStorage.removeItem(FARE_OFFER_STORAGE_KEY)
        localStorage.removeItem(CREATE_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY)

        if (isTransfer) {
          setActiveStep(4)
        }

        window.scrollTo(0, 0)
      }

      dispatch(
        createFlightBooking({
          request: order,
          onSuccess
        })
      )
    }
  }, [dispatch, isTransfer])

  useEffect(() => {
    if (paymentSuccess || setupPaymentDetailsSuccess) {
      if (!isTransfer) {
        handleSubmitOrder()
      }

      setActiveStep(3)
      window.scrollTo(0, 0)
    }
  }, [paymentSuccess, setupPaymentDetailsSuccess, handleSubmitOrder, isTransfer])

  // TODO: Handle paymentFailure

  const handleStepClick = (step: number) => {
    // Prevent going back to step 1
    if (step !== 0) {
      setActiveStep(step)
    }

    if (step < 2) {
      dispatch(resetCreateFlightBookingPaymentIntent())
    }
  }

  const steps: Step[] = useMemo(() => {
    return [
      { label: 'Choose Flights' },
      { label: 'Passenger and Seat Details' },
      { label: 'Payment' },
      ...(isTransfer ? [{ label: 'Receive Payment' }] : []),
      { label: 'Booking Confirmation' }
    ]
  }, [isTransfer])

  const handleFormChange = useCallback((passengers: PassengerDetailsValues[] | null, isValid: boolean) => {
    if (passengers) {
      const areValuesEmpty = passengers.every((passenger) => passenger.issuingCountry === '')

      setPassengerDetails(passengers)
      setIsPassengerDetailsFormValid(isValid && !areValuesEmpty)
    }
  }, [])

  const onBaggageSelectionsChange = useCallback((selections: BaggageSelections) => {
    setBaggageSelections(selections)
  }, [])

  const handleContinueToPayment = () => {
    setActiveStep(2)
  }

  const onSeatSelectionsChange = useCallback((selections: SeatSelections) => {
    setSeatSelections(selections)
  }, [])

  const handlePaymentSubmission = () => {
    if (passengerDetails && isPassengerDetailsFormValid && isPaymentFormValid) {
      const passengers: CreateFlightBookingPassenger[] = passengerDetails.map((passenger, index) => {
        const passengerBaggageDetails = Object.entries(baggageSelections).flatMap(([, passengerSelections]) => {
          const passengerBaggage = passengerSelections[passenger.id] || []

          return passengerBaggage.map((baggage) => ({
            baggageId: baggage.baggageId,
            segmentId: baggage.segmentId
          }))
        })

        const passengerSeatingDetails = Object.entries(seatSelections).flatMap(([segmentId, passengerSeats]) => {
          const seat = passengerSeats[passenger.id]

          return seat ? [{ seatId: seat.serviceId, segmentId }] : []
        })

        return {
          passengerId: passenger.id,
          title: passenger.title,
          firstName: passenger.firstName,
          lastName: passenger.lastName,
          gender: passenger.gender,
          birthDate: passenger.dateOfBirth,
          phoneNumber: passenger.phoneNumber ? sanitizePhoneNumber(passenger.phoneNumber) : undefined,
          email: index === 0 ? userProfile?.email || undefined : undefined,
          infantPassengerId: '', // TODO: Deduce this based on age of infant in booking
          passportDetails: {
            passportNumber: passenger.passportNumber,
            issuingCountryIataCode: passenger.issuingCountry,
            expiryDate: passenger.expirationDate
          },
          baggageDetails: passengerBaggageDetails.length > 0 ? passengerBaggageDetails : null,
          seatingDetails: passengerSeatingDetails.length > 0 ? passengerSeatingDetails : null
        }
      })

      const orderSubmission: CreateFlightBookingRequest = {
        'external-system-offer-id': fareOffer.id,
        passengers,
        paymentIntentId: createFlightBookingPaymentIntentSuccess?.data?.paymentIntent?.id ?? ''
      }

      localStorage.setItem(FLIGHT_BOOKING_SUBMISSION_STORAGE_KEY, orderSubmission)

      if (!isTransfer) {
        // TODO: Check if Duffel offer is still valid before submitting payment
        // New endpoint will need to be created to check if the offer is still valid
        paymentFormRef.current?.submitPayment()
      } else {
        handleContinueToTransfer()
      }
    }
  }

  const renderStepHeader = () => {
    let headerTitle = ''
    let headerSubtitle = ''

    switch (activeStep) {
      case 0:
        break

      case 1:
        headerTitle = 'Passenger Details'
        headerSubtitle = 'Provide your passenger information using the fields below.'
        break

      case 2:
        headerTitle = 'Payment Details'
        headerSubtitle = 'Set up a card that can be used to pay for your trips.'
        break

      case 3:
        if (isTransfer) {
          headerTitle = 'Payout Details'
          headerSubtitle = 'Connect a bank account (preferably in USD) to receive payments.'
        }
        break

      default:
        return null
    }

    return (
      <Box sx={styles.stepHeader}>
        <Typography variant="h4" sx={styles.title}>
          {headerTitle}
        </Typography>

        {
          <Typography variant="subtitle1" sx={styles.subtitle}>
            {headerSubtitle}
          </Typography>
        }
      </Box>
    )
  }

  const renderStepContent = () => {
    const checkoutMode = isTransfer ? 'setup' : 'payment'
    let handleCreateFlightBookingPaymentIntentRequest = null

    if (activeStep === 1) {
      if (!fareOffer) {
        return null
      }

      return (
        <PassengerDetails
          fareOffer={fareOffer}
          onFormChange={handleFormChange}
          countriesAndCities={countriesAndCities?.data as CountryAndCities[]}
          userProfile={userProfile}
          onBaggageSelectionsChange={onBaggageSelectionsChange}
          onSeatSelectionsChange={onSeatSelectionsChange}
        />
      )
    } else if (activeStep === 2) {
      handleCreateFlightBookingPaymentIntentRequest = () => {
        const payload: CreateFlightBookingPaymentIntentRequestPayload = {
          request: {
            externalFlightBookingOfferId: fareOffer.id,
            flightItineraryId: flightItineraryId,
            additionalServiceIds: [
              ...Object.values(baggageSelections).flatMap((passengerSelections) =>
                Object.values(passengerSelections).flatMap((baggage) => baggage.map((b) => b.baggageId))
              ),
              ...Object.values(seatSelections).flatMap((passengerSeats) =>
                Object.values(passengerSeats).map((seat) => seat.serviceId)
              )
            ]
          }
        }

        dispatch(createFlightBookingPaymentIntent(payload))
      }

      return (
        <PaymentDetails
          mode={checkoutMode}
          redirectPath={routes.flightBooking.path}
          setupIntent={flightBookingPaymentIntent?.data?.setupIntent}
          paymentIntent={flightBookingPaymentIntent?.data?.paymentIntent}
          customerSession={flightBookingPaymentIntent?.data?.customerSession}
          currency={flightBookingPaymentIntent?.data?.currency}
          paymentIntentLoading={createFlightBookingPaymentIntentLoading}
          handleCreatePaymentIntentRequest={handleCreateFlightBookingPaymentIntentRequest}
          setIsPaymentFormValid={setIsPaymentFormValid}
          setPaymentRequestLoading={setPaymentRequestLoading}
          setSetupPaymentLoading={setSetupPaymentLoading}
          paymentFormRef={paymentFormRef}
        />
      )
    } else if (activeStep === 3 && isTransfer) {
      handleCreateFlightBookingPaymentIntentRequest = () => {
        const payload: CreateFlightBookingPaymentIntentRequestPayload = {
          request: {
            externalFlightBookingOfferId: fareOffer.id,
            flightItineraryId: flightItineraryId,
            additionalServiceIds: [
              ...Object.values(baggageSelections).flatMap((passengerSelections) =>
                Object.values(passengerSelections).flatMap((baggage) => baggage.map((b) => b.baggageId))
              ),
              ...Object.values(seatSelections).flatMap((passengerSeats) =>
                Object.values(passengerSeats).map((seat) => seat.serviceId)
              )
            ]
          }
        }

        dispatch(createFlightBookingPaymentIntent(payload))
      }

      return (
        <PaymentDetails
          mode={'setup'}
          redirectPath={routes.flightBooking.path}
          setupIntent={flightBookingPaymentIntent?.data?.setupIntent}
          paymentIntent={flightBookingPaymentIntent?.data?.paymentIntent}
          accountSession={flightBookingPaymentIntent?.data?.accountSession}
          customerSession={flightBookingPaymentIntent?.data?.customerSession}
          currency={flightBookingPaymentIntent?.data?.currency}
          paymentIntentLoading={createFlightBookingPaymentIntentLoading}
          handleCreatePaymentIntentRequest={handleCreateFlightBookingPaymentIntentRequest}
          setIsPaymentFormValid={setIsPaymentFormValid}
          setPaymentRequestLoading={setPaymentRequestLoading}
          isTransfer
          onOnboardingComplete={() => setIsOnboadingComplete(true)}
        />
      )
    } else if ((isTransfer && activeStep === 4) || (!isTransfer && activeStep === 3)) {
      return (
        <PaymentSubmittedSuccessfully
          title="Flight Booked Successfully"
          description="Your flight was successfully booked. An confirmation email will be sent to you shortly with your booking details."
          button={{
            text: 'View My Trip',
            onClick: () => navigate(routes.myTrips.path),
            buttonType: 'primary'
          }}
          loadingState={{ loading: createFlightBookingLoading, text: 'Booking your flight' }}
          success={createFlightBookingSuccess !== null}
          errorState={
            createFlightBookingError
              ? {
                  title: 'Error Processing Order',
                  subtitle: `There was an issue while processing your order. Try submitting again.`,
                  button: {
                    text: 'Try Again',
                    onClick: handleSubmitOrder
                  }
                }
              : undefined
          }
        />
      )
    } else {
      return null
    }
  }

  const handleContinueToTransfer = () => {
    setSetupPaymentLoading(true)

    const payload: CreateAccountSessionRequestPayload = {
      onSuccess: (response) => {
        if (flightBookingPaymentIntent) {
          const storeFlightBookingPaymentIntent = {
            ...flightBookingPaymentIntent,
            data: {
              ...flightBookingPaymentIntent.data,
              accountSession: {
                secret: response.data.secret,
                expiresAt: response.data.expiresAt
              }
            }
          }

          localStorage.setItem(CREATE_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY, storeFlightBookingPaymentIntent)
          localStorage.setItem(FARE_OFFER_STORAGE_KEY, fareOffer)
          paymentFormRef.current?.submitPayment()
        }
      }
    }

    dispatch(createAccountSession(payload))
  }

  const renderButtons = () => {
    switch (activeStep) {
      case 1:
        return (
          <Button
            text="Continue to Payment"
            fullWidth
            variant={'contained'}
            buttonType="primary"
            onClick={handleContinueToPayment}
            disabled={!isPassengerDetailsFormValid}
            tooltipText={
              !isPassengerDetailsFormValid ? 'Please ensure all required fields are filled correctly.' : undefined
            }
          />
        )

      case 2:
        return (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            buttonType="primary"
            text={isTransfer ? 'Continue to Payout' : 'Submit Order'}
            loading={paymentRequestLoading || setupPaymentLoading || createAccountSessionLoading}
            onClick={handlePaymentSubmission}
            disabled={
              !isTransfer ? !isPaymentFormValid || isTransfer || paymentRequestLoading : createAccountSessionLoading
            }
            tooltipText={!isPaymentFormValid ? 'Please ensure all required fields are filled correctly.' : undefined}
          />
        )

      case 3:
        if (isTransfer) {
          return (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              buttonType="primary"
              text={'Submit Order'}
              loading={createFlightBookingLoading}
              onClick={handleSubmitOrder}
              disabled={!isOnboadingComlpete}
              tooltipText={!isOnboadingComlpete ? 'Please complete the onboarding process.' : undefined}
            />
          )
        }
        break
      default:
        return null
    }
  }

  let isOverviewSectionVisible =
    (activeStep !== (isTransfer ? 4 : 3) || setupPaymentDetailsSuccess || paymentSuccess) && activeStep != 4

  if (!isTransfer && activeStep === 3) {
    isOverviewSectionVisible = false
  }

  return (
    <Stepper stepperWidth="70%" activeStep={activeStep} steps={steps} showStepper onStepClick={handleStepClick}>
      {renderStepHeader()}

      <Grid container spacing={isOverviewSectionVisible ? 4 : 0} sx={styles.gridContainer}>
        <Grid item xs={12} md={isOverviewSectionVisible ? 9 : 12}>
          <Box sx={styles.contentContainer} id="step-content-container">
            {renderStepContent()}
          </Box>
        </Grid>

        {isOverviewSectionVisible && (
          <Grid item xs={12} sm={3}>
            <Box sx={styles.tripOverviewContainer}>
              <TripOverview
                offerData={fareOffer}
                baggageSelections={baggageSelections}
                seatSelections={seatSelections}
                currency={flightBookingPaymentIntent?.data?.currency}
                discount={flightBookingPaymentIntent?.data?.discount}
                tax={flightBookingPaymentIntent?.data?.tax}
                fees={flightBookingPaymentIntent?.data?.fees}
                subtotal={flightBookingPaymentIntent?.data?.subtotal}
                total={flightBookingPaymentIntent?.data?.total}
              />
            </Box>

            {renderButtons()}
          </Grid>
        )}
      </Grid>
    </Stepper>
  )
}

export default FlightBooking
