import React from 'react'

import { Box, Divider, Typography } from '@mui/material'

import Price from '../Price/Price.component'

import styles from './Overview.styles'
import { OverviewProps } from './Overview.types'

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {
  const { items, currency, tax, fees, subtotal, total } = props

  const overviewCurrency = currency ?? items[0].currency

  const totalItems = items
    .filter((item) => item.stockStatus !== 'Out of Stock')
    .reduce((sum, item) => sum + item.quantity, 0)

  const overviewSubtotal =
    subtotal ??
    items
      .filter((item) => item.stockStatus !== 'Out of Stock')
      .reduce((sum, item) => sum + item.price * item.quantity, 0)

  const overviewTotal = total ?? overviewSubtotal + (fees ?? 0) + (tax ?? 0)

  return (
    <Box sx={styles.container}>
      <Typography variant="h6" sx={styles.title}>
        Overview
      </Typography>

      <Divider sx={styles.divider} />

      <Box sx={styles.row}>
        <Typography variant="subtitle1" sx={styles.subtotalTitleText}>
          Subtotal
        </Typography>
      </Box>

      {overviewSubtotal && (
        <Box sx={styles.row}>
          <Typography sx={styles.fieldText}>
            {totalItems} item{totalItems > 1 ? 's' : ''}
          </Typography>

          <Typography sx={styles.fieldText}>
            {overviewCurrency} ${overviewSubtotal?.toFixed(2)}
          </Typography>
        </Box>
      )}

      {fees && (
        <Box sx={styles.row}>
          <Typography sx={styles.fieldText}>Hourrier Fees</Typography>

          <Typography sx={styles.fieldText}>
            {overviewCurrency} ${fees?.toFixed(2)}
          </Typography>
        </Box>
      )}

      {tax && (
        <Box sx={styles.row}>
          <Typography sx={styles.fieldText}>Tax</Typography>

          <Typography sx={styles.fieldText}>
            ~ {overviewCurrency} ${tax.toFixed(2)}
          </Typography>
        </Box>
      )}

      {total && (
        <>
          <Divider sx={styles.divider} />

          <Box sx={styles.amountToPayContainer}>
            <Typography variant="body1" sx={styles.amountToPayText}>
              Amount to Pay
            </Typography>

            <Price showSeparator={true} currency={overviewCurrency} superscript={false} price={overviewTotal} />
          </Box>
        </>
      )}
    </Box>
  )
}

export default Overview
