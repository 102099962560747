import React from 'react'

import { Box, Container, Divider, Typography } from '@mui/material'
import * as Yup from 'yup'

import Form from '../../shared/components/Form/Form.component'
import Link from '../../shared/components/Link/Link.component'

import { login } from '../../redux/states/auth/login/login.slice'
import { LoginRequest } from '../../redux/states/auth/login/login.types'
import { FlightItinerary } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { FareOffer } from '../../redux/states/flights/getFlightFares/types/FareDetailsResponse.types'
import { OfferRequest } from '../../redux/states/offers/getOfferRequests/getOfferRequests.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { FormField } from '../../shared/components/Form/Form.types'
import { TripPreferences } from '../../shared/components/TripPreferencesModal/TripPreferencesModal.types'
import localStorage from '../../shared/functions/LocalStorage/localStorage'
import { logEvent } from '../../shared/functions/Logger/logger.functions'
import { UserRole } from '../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import {
  CART_STORAGE_KEY,
  FLIGHT_ITINERARY_ID_STORAGE_KEY,
  REDIRECT_TO_CART_DELIVERY_KEY
} from '../Cart/Cart.dictionary'
import { CartItem } from '../Cart/Cart.types'
import { FlightSearchInitialValues } from '../Travel/components/FlightSearchWidget/FlightSearchWidget.types'
import {
  EXISTING_FLIGHT_ITINERARY_KEY,
  FARE_OFFER_STORAGE_KEY,
  IS_BOOKING_FLIGHT_KEY,
  ITINERARY_KEY,
  REDIRECT_TO_TRIP_SUMMARY_KEY,
  SEARCHED_VALUES_STORAGE_KEY,
  SELECTED_OFFERS_STORAGE_KEY,
  TRIP_PREFERENCES_STORAGE_KEY
} from '../TripSummary/TripSummary.dictionary'
import styles from './Login.styles'
import { LoginFormValues } from './Login.types'

const Login: React.FC = () => {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.login)
  const navigate = router.navigate()

  const initialValues: LoginFormValues = {
    email: '',
    password: ''
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[A-Z]/, 'Password must contain one uppercase letter')
      .matches(/[a-z]/, 'Password must contain one lowercase letter')
      .matches(/[0-9]/, 'Password must contain one number')
      .required('Required')
  })

  const onSubmit = (values: LoginFormValues) => {
    const loginRequest: LoginRequest = {
      email: values.email,
      password: values.password
    }

    const onSuccess = () => {
      const userRole = getUserRole()
      const storedCart: CartItem[] | null = localStorage.getItem(CART_STORAGE_KEY)
      const shouldRedirectToCartDelivery: boolean | null =
        localStorage.getItem(REDIRECT_TO_CART_DELIVERY_KEY) && userRole === UserRole.shopper
      const shouldRedirectToTripSummary =
        localStorage.getItem(REDIRECT_TO_TRIP_SUMMARY_KEY) && userRole === UserRole.traveler

      if (shouldRedirectToCartDelivery) {
        const flightItineraryId: number | null = localStorage.getItem(FLIGHT_ITINERARY_ID_STORAGE_KEY)

        navigate(routes.cart.path, {
          state: {
            cart: storedCart,
            shouldNavigateToDelivery: shouldRedirectToCartDelivery,
            flightItineraryId: Number(flightItineraryId)
          }
        })

        localStorage.removeItem(REDIRECT_TO_CART_DELIVERY_KEY)
        localStorage.removeItem(CART_STORAGE_KEY)
        localStorage.removeItem(FLIGHT_ITINERARY_ID_STORAGE_KEY)
      } else if (shouldRedirectToTripSummary) {
        const fareOffer: FareOffer | null = localStorage.getItem(FARE_OFFER_STORAGE_KEY)
        const selectedOffers: OfferRequest[] | null = localStorage.getItem(SELECTED_OFFERS_STORAGE_KEY)
        const tripPreferences: TripPreferences | null = localStorage.getItem(TRIP_PREFERENCES_STORAGE_KEY)
        const searchedValues: FlightSearchInitialValues | null = localStorage.getItem(SEARCHED_VALUES_STORAGE_KEY)
        const existingFlightItinerary: FlightItinerary | null = localStorage.getItem(EXISTING_FLIGHT_ITINERARY_KEY)
        const itinerary: FlightItinerary | null = localStorage.getItem(ITINERARY_KEY)
        const isBookingFlight: boolean | null = localStorage.getItem(IS_BOOKING_FLIGHT_KEY)

        localStorage.removeItem(REDIRECT_TO_TRIP_SUMMARY_KEY)
        localStorage.removeItem(FARE_OFFER_STORAGE_KEY)
        localStorage.removeItem(SELECTED_OFFERS_STORAGE_KEY)
        localStorage.removeItem(TRIP_PREFERENCES_STORAGE_KEY)
        localStorage.removeItem(SEARCHED_VALUES_STORAGE_KEY)
        localStorage.removeItem(EXISTING_FLIGHT_ITINERARY_KEY)
        localStorage.removeItem(ITINERARY_KEY)
        localStorage.removeItem(IS_BOOKING_FLIGHT_KEY)

        navigate(routes.tripSummary.path, {
          state: {
            fare: fareOffer,
            selectedOffers,
            tripPreferences,
            searchedValues,
            existingFlightItinerary,
            itinerary,
            isBookingFlight
          }
        })
      } else {
        switch (userRole) {
          case UserRole.shopper:
            navigate(routes.shop.path, { replace: true })
            break

          default:
            navigate(routes.home.path, { replace: true })
        }
      }
    }

    dispatch(login({ loginRequest, onSuccess }))

    logEvent('login_button_clicked', {
      email: values.email
    })
  }

  const formFields: FormField[] = [
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      placeholder: 'john.doe@example.com',
      grid: { xs: 12 },
      required: true,
      fullWidth: true
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      autoComplete: 'current-password',
      grid: { xs: 12 },
      required: true,
      fullWidth: true
    }
  ]

  return (
    <Container component="main" maxWidth="sm" sx={styles.container}>
      <Form
        title="Start Booking Today"
        subtitle="You could earn big on your next trip"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: unknown) => onSubmit(values as LoginFormValues)}
        formFields={formFields}
        loading={loading}
        buttonText="Log In"
      />

      <Box sx={styles.alternativeActionsContainer}>
        <Typography sx={styles.loginTextContainer}>
          <Typography sx={styles.loginText}>Don't have an account already?</Typography>

          <Typography sx={styles.loginText}>
            <Link href={routes.signUp.path} ml={0.5} showColor>
              Sign Up
            </Link>
          </Typography>
        </Typography>

        <Typography sx={styles.loginText}>
          <Link href={routes.forgetPassword.path} ml={0.5} showColor>
            Forgot Password?
          </Link>
        </Typography>
      </Box>

      <Box sx={styles.termsContainer}>
        <Divider sx={styles.divider} />

        <Typography sx={styles.termsTextContainer}>
          <Typography sx={styles.termsText}>By logging in, you agree to our </Typography>

          <Typography sx={styles.termsText}>
            <Link href={routes.termsOfServices.path} ml={0.5} mr={0.5} showColor isUnderlined>
              Terms of Service
            </Link>
          </Typography>

          <Typography sx={styles.termsText}>and</Typography>

          <Typography sx={styles.termsText}>
            <Link href={routes.privacyPolicy.path} ml={0.5} mr={0.5} showColor isUnderlined>
              Privacy Policy.
            </Link>
          </Typography>
        </Typography>
      </Box>
    </Container>
  )
}

export default Login
