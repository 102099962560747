import { ReactNode } from 'react'

export function truncateString(str: string, maxLength: number = 50): string {
  if (!str) {
    return ''
  } else if (str.length <= maxLength) {
    return str
  } else {
    return str.slice(0, maxLength - 3) + '...'
  }
}

export function sentenceCase(str: string): string {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function joinStringWithBullet(strings: (string | ReactNode)[]): ReactNode | string {
  return strings.reduce((prev, curr) => [prev, '\u00A0 • \u00A0', curr])
}

export function joinStringWithDash(strings: (string | ReactNode)[]): ReactNode | string {
  return strings.reduce((prev, curr) => [prev, '\u00A0 - \u00A0', curr])
}

/**
 * Sanitizes a phone number by removing all non-numeric characters except for '+'.
 * @param phoneNumber - The phone number to sanitize.
 * @returns The sanitized phone number.
 * @example
 * sanitizePhoneNumber("+1 876 307 1239") // "+18763071239"
 */
export function sanitizePhoneNumber(phoneNumber: string | undefined): string | undefined {
  return phoneNumber ? phoneNumber.replace(/[^0-9+]/g, '') : undefined
}

export function formatMoney(price: number | string | undefined): string {
  return price ? Number(price).toFixed(2) : '0.00'
}
