import { SxProps } from '@mui/material'

import theme from '../../../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    marginBottom: theme.spacing(2)
  } as SxProps,
  select: {
    width: '100%'
  } as SxProps,
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2)
  } as SxProps,
  route: {
    fontWeight: 'bold'
  } as SxProps,
  duration: {
    color: theme.palette.text.secondary
  } as SxProps
}

export default styles
