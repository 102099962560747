import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Box, Typography } from '@mui/material'
import _ from 'lodash'
import * as Yup from 'yup'

import Avatar from '../../shared/components/Avatar/Avatar.component'
import Button from '../../shared/components/Button/Button.component'
import { EmptyState } from '../../shared/components/EmptyState/EmptyState.component'
import Form from '../../shared/components/Form/Form.component'
import Loader from '../../shared/components/Loader/Loader.component'
import StripeCheckoutForm from '../../shared/components/StripeCheckoutForm/StripeCheckoutForm.component'

import {
  createCustomerSession,
  resetCreateCustomerSessionState
} from '../../redux/states/payments/createCustomerSession/createCustomerSession.slice'
import { addDeliveryAddress } from '../../redux/states/profile/addDeliveryAddress/addDeliveryAddress.slice'
import { getProfile } from '../../redux/states/profile/getProfile/getProfile.slice'
import { GetProfileData } from '../../redux/states/profile/getProfile/getProfile.types'
import { updateContactInfo } from '../../redux/states/profile/updateContactInfo/updateContactInfo.slice'
import { updateDeliveryAddress } from '../../redux/states/profile/updateDeliveryAddress/updateDeliveryAddress.slice'
import { updatePersonalInfo } from '../../redux/states/profile/updatePersonalInfo/updatePersonalInfo.slice'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { AvatarSize } from '../../shared/components/Avatar/Avatar.types'
import { FormField } from '../../shared/components/Form/Form.types'
import { PaymentFormRef } from '../../shared/components/StripeCheckoutForm/StripeCheckoutForm.types'
import cities from '../../shared/dictionaries/cities.dictionaries.json'
import countries from '../../shared/dictionaries/countries.dictionaries.json'
import date, { dateTimeFormats } from '../../shared/functions/Date/date.functions'
import { sanitizePhoneNumber } from '../../shared/functions/String/string.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './Profile.styles'
import { ContactDetailsFormValues, DeliveryAddressFormValues, PersonalDetailsFormValues } from './Profile.types'
import { ProfileSection } from './Profile.types'

const Profile: React.FC = () => {
  const { success: profileSuccess, loading: profileLoading } = useAppSelector((state) => state.getProfile)
  const { loading: updatePersonalInfoLoading } = useAppSelector((state) => state.updatePersonalInfo)
  const { loading: updateContactInfoLoading } = useAppSelector((state) => state.updateContactInfo)
  const { loading: addDeliveryAddressLoading } = useAppSelector((state) => state.addDeliveryAddress)
  const { loading: updateDeliveryAddressLoading } = useAppSelector((state) => state.updateDeliveryAddress)

  const [isPersonalDetailsDisabled, setIsPersonalDetailsDisabled] = React.useState<boolean>(false)
  const [isContactDetailsDisabled, setIsContactDetailsDisabled] = React.useState<boolean>(false)
  const [personalDetailsInitialValues, setPersonalDetailsInitialValues] = React.useState<PersonalDetailsFormValues>({
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: ''
  })

  const [contactDetailsInitialValues, setContactDetailsInitialValues] = React.useState<ContactDetailsFormValues>({
    mobileNumber: '',
    email: ''
  })

  const [deliveryAddressInitialValues, setDeliveryAddressInitialValues] = React.useState<DeliveryAddressFormValues>({
    deliveryAddressId: 0,
    cityId: 83, // Default to the Kingston
    countryId: 83, // Default to the Jamaica
    streetAddress: ''
  })

  const initialSection = router.getNavigationProps().state?.selectedSection ?? 'basic'

  const [selectedSection, setSelectedSection] = useState<ProfileSection>(initialSection)

  const [, setIsPaymentFormValid] = useState(false)
  const [, setPaymentRequestLoading] = useState(false)
  const [setupPaymentLoading, setSetupPaymentLoading] = useState(false)
  const [isFormLoaded, setIsFormLoaded] = useState(false)
  const [hasFormBeenInteractedWith, setHasFormBeenInteractedWith] = useState(false)
  const paymentFormRef = useRef<PaymentFormRef>(null)

  const navigate = router.navigate()
  const dispatch = useAppDispatch()
  const redirectStatus = router.getUrlParam('redirect_status')

  const { success: customerSessionSuccess, loading: customerSessionLoading } = useAppSelector(
    (state) => state.createCustomerSession
  )

  useEffect(() => {
    return () => {
      dispatch(resetCreateCustomerSessionState())
    }
  }, [dispatch])

  useEffect(() => {
    if (redirectStatus === 'succeeded') {
      setSelectedSection('payment')
      dispatch(createCustomerSession({}))
    }
  }, [redirectStatus, dispatch])

  useEffect(() => {
    if (initialSection === 'payment') {
      dispatch(createCustomerSession({}))
    }
  }, [dispatch, initialSection])

  const updateInitialValues = useCallback((data: GetProfileData) => {
    let dateOfBirth = ''

    if (data.dateOfBirth) {
      dateOfBirth = date(data.dateOfBirth).format(dateTimeFormats.date.api) ?? ''
    }

    setPersonalDetailsInitialValues({
      firstName: data.firstName || '',
      middleName: data.middleName || '',
      lastName: data.lastName || '',
      dateOfBirth
    })

    setContactDetailsInitialValues({
      mobileNumber: data.mobileNumber || '',
      email: data.email || ''
    })

    /**
     * Defaulting to the first item in the array of delivery addresses
     * until we separate the delivery addresses from the profile data
     */
    setDeliveryAddressInitialValues({
      deliveryAddressId: data?.deliveryAddresses[0]?.id || 0,
      cityId: data?.deliveryAddresses[0]?.city_id || 83,
      countryId: data?.deliveryAddresses[0]?.country_id || 83,
      streetAddress: data?.deliveryAddresses[0]?.street_address || ''
    })
  }, [])

  useEffect(() => {
    const onSuccess = (data: GetProfileData) => {
      updateInitialValues(data)
    }

    dispatch(getProfile({ onSuccess }))
  }, [dispatch, updateInitialValues])

  const personalDetailsFields: FormField[] = [
    {
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      autoComplete: 'given-name',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'middleName',
      label: 'Middle Name',
      type: 'text',
      autoComplete: 'additional-name',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
    },
    {
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      autoComplete: 'family-name',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'dateOfBirth',
      label: 'Date of Birth',
      type: 'date',
      autoComplete: 'bday',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
    }
  ]

  const contactDetailsFields: FormField[] = [
    {
      name: 'mobileNumber',
      label: 'Mobile Number',
      type: 'tel',
      autoComplete: 'tel',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'cityId',
      label: 'City',
      type: 'select',
      options: cities.map((city) => ({ value: city.id, label: city.name })),
      autoComplete: 'address-level2',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
      // onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleCityChange(Number(event.target.value))
    },
    {
      name: 'countryId',
      label: 'Country',
      type: 'select',
      options: countries.map((country) => ({ value: country.id, label: country.name })),
      autoComplete: 'country',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
    },
    {
      name: 'streetAddress',
      label: 'Street Address',
      type: 'text',
      autoComplete: 'street-address',
      grid: { xs: 12 },
      fullWidth: true
    }
  ]

  const personalDetailsValidationSchema = Yup.object({
    firstName: Yup.string().min(2, 'First name must be at least 2 characters long').required('Required'),
    middleName: Yup.string().min(2, 'Middle name must be at least 2 characters long'),
    lastName: Yup.string().min(2, 'Last name must be at least 2 characters long').required('Required'),
    dateOfBirth: Yup.date().optional()
  })

  const contactDetailsValidationSchema = Yup.object({
    mobileNumber: Yup.string().min(10, 'Mobile number must be at least 10 characters long').required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    cityId: Yup.number().required('Required'),
    countryId: Yup.number().required('Required'),
    streetAddress: Yup.string().min(2, 'Street address must be at least 2 characters long')
  })

  const handlePersonalDetailsSubmit = (values: PersonalDetailsFormValues) => {
    const onSuccess = () => {
      setIsPersonalDetailsDisabled(false)
      dispatch(getProfile({ onSuccess: updateInitialValues }))
    }

    dispatch(updatePersonalInfo({ updatePersonalInfoRequest: values, onSuccess }))
  }

  const handleContactDetailsSubmit = (values: ContactDetailsFormValues & DeliveryAddressFormValues) => {
    const onSuccess = () => {
      setIsContactDetailsDisabled(false)
      dispatch(getProfile({ onSuccess: updateInitialValues }))
    }

    const contactDetailsValues: ContactDetailsFormValues = {
      mobileNumber: values.mobileNumber ? sanitizePhoneNumber(values.mobileNumber) || '' : '',
      email: values.email
    }

    const deliveryAddressValues: DeliveryAddressFormValues = {
      deliveryAddressId: values.deliveryAddressId,
      cityId: values.cityId,
      countryId: values.countryId,
      streetAddress: values.streetAddress
    }

    const deliveryValues = {
      ...deliveryAddressValues,
      postalCode: '00000'
    }

    const isDeliveryAddressEmpty = Object.values(deliveryAddressValues).every((value) => value === '')

    if (!isDeliveryAddressEmpty) {
      if (_.isEmpty(profileSuccess?.data?.deliveryAddresses)) {
        dispatch(addDeliveryAddress({ addDeliveryAddressRequest: deliveryValues, onSuccess }))
      } else {
        dispatch(updateDeliveryAddress({ updateDeliveryAddressRequest: deliveryValues, onSuccess }))
      }
    }

    dispatch(updateContactInfo({ updateContactInfoRequest: contactDetailsValues, onSuccess }))
  }

  // const handleCityChange = (cityId: number) => {
  //   const selectedCity = cities.find((city) => city.id === cityId)

  //   if (selectedCity) {
  //     const countryId = selectedCity.country_id

  //     setDeliveryAddressInitialValues((prevValues) => ({
  //       ...prevValues,
  //       cityId,
  //       countryId
  //     }))
  //   }
  // }

  const handleSectionChange = (section: ProfileSection) => {
    setSelectedSection(section)

    if (section === 'payment' && !customerSessionSuccess) {
      dispatch(createCustomerSession({}))
    }
  }

  const handleSavePaymentMethod = () => {
    paymentFormRef.current?.submitPayment()

    // TODO: Create endpoint to record that the payment method was updated on Stripe
    // This will be used to remove the error message from the offer request
    // And trigger the email to the user that the payment method was updated
  }

  const profileHeader = () => {
    return (
      <Box sx={styles.profileHeader}>
        <Box sx={styles.profilePictureContainer}>
          <Avatar
            size={AvatarSize.xLarge}
            firstName={profileSuccess?.data?.firstName}
            lastName={profileSuccess?.data?.lastName}
            noRightMargin
          />
        </Box>

        <Button
          buttonType={selectedSection === 'basic' ? 'primary' : 'tertiary'}
          fullWidth
          text="Basic Information"
          style={styles.profileButton}
          onClick={() => handleSectionChange('basic')}
        />

        <Button
          buttonType={selectedSection === 'payment' ? 'primary' : 'tertiary'}
          fullWidth
          text="Payment Methods"
          onClick={() => handleSectionChange('payment')}
        />
      </Box>
    )
  }

  const renderContent = () => {
    if (selectedSection === 'basic') {
      return (
        <>
          <Form
            title="Personal Details"
            initialValues={{ ...personalDetailsInitialValues }}
            onSubmit={(values: unknown) => handlePersonalDetailsSubmit(values as PersonalDetailsFormValues)}
            formFields={personalDetailsFields}
            validationSchema={personalDetailsValidationSchema}
            buttonText="Save"
            isContained
            isEditable={isPersonalDetailsDisabled}
            setIsEditable={setIsPersonalDetailsDisabled}
            loading={profileLoading || updatePersonalInfoLoading}
            enableReinitialize
          />

          <Form
            title="Contact Details"
            initialValues={{ ...contactDetailsInitialValues, ...deliveryAddressInitialValues }}
            onSubmit={(values: unknown) =>
              handleContactDetailsSubmit(values as ContactDetailsFormValues & DeliveryAddressFormValues)
            }
            formFields={contactDetailsFields}
            validationSchema={contactDetailsValidationSchema}
            buttonText="Save"
            isContained
            isEditable={isContactDetailsDisabled}
            setIsEditable={setIsContactDetailsDisabled}
            loading={
              profileLoading || addDeliveryAddressLoading || updateDeliveryAddressLoading || updateContactInfoLoading
            }
            enableReinitialize
          />

          <Box sx={styles.changePasswordContainer}>
            <Typography sx={styles.changePasswordText}>
              If you want to change your password please click the option. A email with a link to change your password
              will be sent to the email attached to this account.
            </Typography>

            <Button
              buttonType="primary"
              outline
              text="Change Password"
              onClick={() => navigate(routes.changePassword.path)}
            />
          </Box>
        </>
      )
    } else {
      if (customerSessionLoading && !isFormLoaded) {
        return <Loader loading={customerSessionLoading} marginTop={10} text="Loading payment methods..." />
      } else if (
        customerSessionSuccess?.data?.customerSessionClientSecret &&
        customerSessionSuccess?.data?.setupIntentClientSecret
      ) {
        return (
          <Box sx={styles.paymentMethodsContainer}>
            <StripeCheckoutForm
              mode="setup"
              redirectPath={routes.profile.path}
              paymentIntentClientSecret={customerSessionSuccess.data.setupIntentClientSecret}
              customerSessionClientSecret={customerSessionSuccess.data.customerSessionClientSecret}
              setIsPaymentFormValid={setIsPaymentFormValid}
              setPaymentRequestLoading={setPaymentRequestLoading}
              setSetupPaymentLoading={setSetupPaymentLoading}
              paymentFormRef={paymentFormRef}
              currency="usd"
              showTitle
              setIsFormLoaded={setIsFormLoaded}
              setHasFormBeenInteractedWith={setHasFormBeenInteractedWith}
            />

            <Button
              buttonType="primary"
              fullWidth
              altText="Save payment method"
              text="Save"
              onClick={handleSavePaymentMethod}
              loading={setupPaymentLoading}
              disabled={!hasFormBeenInteractedWith}
            />
          </Box>
        )
      } else {
        return (
          <Box sx={styles.paymentMethodsContainer}>
            <EmptyState
              title="Payment Method Error"
              subtitle="There was an issue creating your payment method. Please try again shortly."
              button={{
                text: 'Try Again',
                onClick: () => dispatch(createCustomerSession({}))
              }}
            />
          </Box>
        )
      }
    }
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h4" sx={styles.titleText}>
        Profile
      </Typography>

      <Box sx={styles.contentContainer} id="profile-content-container">
        {profileHeader()}

        <Box sx={styles.profileFormsContainer} id="profile-forms-container">
          {renderContent()}
        </Box>
      </Box>
    </Box>
  )
}

export default Profile
