import React, { useCallback, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import FlightDealsCarousel from '../../shared/components/FlightDealsCarousel/FlightDealsCarousel.component'
import PageBanner from '../../shared/components/PageBanner/PageBanner.component'
import StepByStepGuide from '../../shared/components/StepByStepGuide/StepByStepGuide.component'
import TestimonialCarousel from '../../shared/components/TestimonialCarousel/TestimonialCarousel.component'
import FlightSearchWidget from './components/FlightSearchWidget/FlightSearchWidget.component'

import { PageBannerImage } from '../../redux/states/firebaseRemoteConfig/firebaseRemoteConfig.types'
import { getPublicOffers } from '../../redux/states/offerRequest/getPublicOffers/getPublicOffers.slice'
import {
  GetPublicOffersRequest,
  GetPublicOffersSuccessResponse,
  OfferRequest
} from '../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import { getSupportedLoyaltyPrograms } from '../../redux/states/supportedLoyaltyProgram/getSupportedLoyaltyPrograms/getSupportedLoyaltyPrograms.slice'
import { RootState } from '../../redux/store/store.types'
import { UserRole } from '../../shared/functions/UserRole/userRoleManagement.types'
import { setUserRole } from '../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import dictionary from './Travel.dictionary'
import styles from './Travel.styles'

const TravelPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { values } = useAppSelector((state: RootState) => state.firebaseRemoteConfig)
  const { loading: isLoadingDeals } = useAppSelector((state: RootState) => state.getPublicOffers)
  const travelBannerImages = (values?.travelBannerImages as PageBannerImage[]) ?? []
  const [deals, setDeals] = useState<OfferRequest[]>([])

  useEffect(() => {
    setUserRole(UserRole.traveler)
  }, [])

  useEffect(() => {
    dispatch(getSupportedLoyaltyPrograms({}))
  }, [dispatch])

  const fetchOffers = useCallback(async (): Promise<void> => {
    const getPublicOffersPayload: GetPublicOffersRequest = {
      page: 1,
      size: 6,
      searchQuery: ''
    }

    dispatch(
      getPublicOffers({
        getPublicOffersRequest: getPublicOffersPayload,
        onSuccess: (response: GetPublicOffersSuccessResponse) => {
          setDeals(response.data.results)
        }
      })
    )
  }, [dispatch])

  useEffect(() => {
    fetchOffers()
  }, [fetchOffers])

  return (
    <Box sx={styles.container}>
      <Box sx={styles.bannerContainer}>
        <PageBanner
          images={travelBannerImages}
          title="Save 50% Instantly on Flights. Carry Items for Friends and Family."
          subtitle="Book your flight for less by accepting to courrier items for family and friends of friends."
          link={{ text: 'How it works?', url: '#' }}
        />

        <FlightSearchWidget />
      </Box>

      <StepByStepGuide
        steps={dictionary.howToTravelAndEarn.steps}
        title={dictionary.howToTravelAndEarn.title}
        subtitle={dictionary.howToTravelAndEarn.subtitle}
      />

      <FlightDealsCarousel deals={deals} onRefresh={fetchOffers} isLoadingDeals={isLoadingDeals} />

      <TestimonialCarousel />
    </Box>
  )
}

export default TravelPage
