import React from 'react'

import { Box, Divider, Typography } from '@mui/material'

import Button from '../../../../shared/components/Button/Button.component'
import Price from '../../../Cart/components/Price/Price.component'

import date, { dateTimeFormats, formatDuration } from '../../../../shared/functions/Date/date.functions'
import styles from './TripOverview.styles'
import { TripOverviewProps } from './TripOverview.types'

const TripOverview: React.FC<TripOverviewProps> = (props) => {
  const { offerData, baggageSelections, seatSelections, onEditFlight, currency, discount, fees, tax, subtotal, total } =
    props

  const baggageCost = Object.values(baggageSelections ?? {}).reduce(
    (total, passengerSelections) =>
      total +
      Object.values(passengerSelections).reduce(
        (passengerTotal, baggageItems) =>
          passengerTotal + baggageItems.reduce((itemTotal, item) => itemTotal + item.cost, 0),
        0
      ),
    0
  )

  const seatCost = Object.values(seatSelections ?? {}).reduce(
    (total, passengerSeats) =>
      total +
      Object.values(passengerSeats).reduce((passengerTotal, seat) => passengerTotal + (Number(seat?.price) ?? 0), 0),
    0
  )

  const overviewSubtotal = subtotal ? subtotal - (baggageCost + seatCost) : Number(offerData.total_amount)
  const overviewCurrency = currency ?? offerData.base_currency
  const overviewTotal = total ?? overviewSubtotal + baggageCost + seatCost

  const overviewItems = [
    {
      title: 'Flight',
      value: overviewSubtotal,
      show: true
    },
    {
      title: 'Extra Baggage',
      value: baggageCost,
      show: baggageCost !== 0,
      isPositive: true
    },
    {
      title: 'Seats',
      value: seatCost,
      show: seatCost !== 0,
      isPositive: true
    },
    {
      title: 'Items Value',
      value: fees && tax ? fees + tax : 0,
      show: fees && tax ? fees + tax !== 0 : false,
      isNegative: true
    },
    {
      title: 'Flight Discount',
      value: discount ?? 0,
      show: discount ? discount > 0 : false,
      isNegative: true
    }
  ]

  return (
    <Box sx={styles.container}>
      <Box sx={styles.cardTop}>
        <Typography variant="h6" sx={styles.title}>
          Overview
        </Typography>

        {onEditFlight && (
          <Button
            onClick={onEditFlight}
            text="Edit"
            altText="Edit flight"
            iconPosition="center"
            buttonType="tertiary"
          />
        )}
      </Box>

      <Divider sx={styles.divider} />

      {offerData.slices.map((slice) => (
        <Box key={slice.id} sx={styles.section}>
          <Box sx={styles.sectionContent}>
            <Typography variant="body2" sx={styles.sectionTitle}>
              {slice.origin.city_name} to {slice.destination.city_name}
            </Typography>

            {slice.segments.map((segment) => (
              <Box key={segment.id}>
                <Typography sx={styles.segmentValueText} variant="body2">
                  {date(segment.departing_at).format(dateTimeFormats.date.long)}
                </Typography>

                <Box sx={styles.gap} />

                <Typography sx={styles.segmentValueText} variant="body2">
                  <span style={styles.timeSpan}>
                    {date(segment.departing_at).format(dateTimeFormats.time.short)} –{' '}
                    {date(segment.arriving_at).format(dateTimeFormats.time.short)}
                  </span>{' '}
                  ({formatDuration(segment.duration)})
                </Typography>

                <Typography sx={styles.segmentValueText} variant="body2">
                  {segment.origin.iata_code} – {segment.destination.iata_code}
                </Typography>
              </Box>
            ))}
          </Box>

          <img src={offerData.owner.logo_symbol_url} alt="Airline Logo" style={styles.logo} />
        </Box>
      ))}

      <Divider sx={styles.divider} />

      <Box sx={styles.amountTopContainer}>
        <Typography variant="subtitle1" sx={styles.sectionTitle}>
          Subtotal
        </Typography>

        {overviewItems.map((item, index) => {
          if (item.show) {
            return (
              <Box sx={styles.row(index === overviewItems.length - 1)}>
                <Typography sx={styles.subtotalTitleText}>{item.title}</Typography>

                <Typography sx={styles.subtotalValue}>
                  {item.isPositive && '+ '}
                  {item.isNegative && '- '}
                  {overviewCurrency} ${item.value.toFixed(2)}
                </Typography>
              </Box>
            )
          } else {
            return null
          }
        })}
      </Box>

      {total && (
        <>
          <Divider sx={styles.divider} />

          <Box sx={styles.amountToPayContainer}>
            <Typography variant="body1" sx={styles.amountToPayTitle}>
              Amount to {overviewTotal > 0 ? 'Pay' : 'Receive'}
            </Typography>

            <Price
              showSeparator={true}
              currency={overviewCurrency}
              superscript={false}
              price={Math.abs(overviewTotal)}
            />
          </Box>
        </>
      )}
    </Box>
  )
}

export default TripOverview
