import React, { useCallback, useEffect } from 'react'

import { Box } from '@mui/material'

import PaginatedPage from '../../shared/components/PaginatedPage/PaginatedPage.component'
import TripDetailsAccordion from './components/TripDetailsAccordion/TripDetailsAccordion.component'

import { getAllFlightItineraries } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.slice'
import {
  FlightItinerary,
  GetAllFlightItinerariesRequest
} from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { EmptyStateProps } from '../../shared/components/EmptyState/EmptyState.types'
import { FilterTab, PaginatedResponse } from '../../shared/components/PaginatedPage/PaginatedPage.types'
import status from '../../shared/dictionaries/status.dictionaries'
import date from '../../shared/functions/Date/date.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './Trips.styles'

const Trips: React.FC = () => {
  const { loading } = useAppSelector((state: RootState) => state.getAllFlightItineraries)

  const isItineraryCancelled = (flightItinerary: FlightItinerary) =>
    flightItinerary.status.name === status.flightStatus.flightBookingCancelledBeforePayment ||
    flightItinerary.status.name === status.flightStatus.flightCancelledAfterPayment

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const fetchFlightItineraries = useCallback(
    async (page: number): Promise<PaginatedResponse<FlightItinerary>> => {
      return new Promise((resolve) => {
        const getAllFlightItinerariesPayload: GetAllFlightItinerariesRequest = {
          page,
          size: 20
        }

        dispatch(
          getAllFlightItineraries({
            request: getAllFlightItinerariesPayload,
            onSuccess: (response) => {
              resolve({
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                results: response.data.results
              })
            }
          })
        )
      })
    },
    [dispatch]
  )

  const filterTabs: FilterTab<unknown>[] = [
    {
      id: 'upcoming',
      label: 'Upcoming',
      filterDef: (flightItineraries: unknown[]) =>
        (flightItineraries as FlightItinerary[]).filter((flightItinerary) => {
          const lastLeg = flightItinerary.itinerary.legs[flightItinerary.itinerary.legs.length - 1]
          const isCurrentDateBeforeLastLegDate = date(date().currentDate).isSameOrBefore(
            date(lastLeg.travelDate).dateTime,
            'day'
          )

          const isTripCancelled = isItineraryCancelled(flightItinerary)

          return isCurrentDateBeforeLastLegDate && !isTripCancelled
        })
    },

    {
      id: 'completed',
      label: 'Completed',
      filterDef: (flightItineraries: unknown[]) =>
        (flightItineraries as FlightItinerary[]).filter((flightItinerary) => {
          const lastLeg = flightItinerary.itinerary.legs[flightItinerary.itinerary.legs.length - 1]
          const isLastLegDateInThePast = date(lastLeg.travelDate).isBefore(date().currentDate)
          const isTripCancelled = isItineraryCancelled(flightItinerary)

          return isLastLegDateInThePast || isTripCancelled
        })
    }
  ]

  const emptyState: EmptyStateProps = {
    title: 'You have no trips available',
    subtitle: 'Book a trip and share your link with friends and family to get started.',
    button: {
      text: 'Book Trip',
      onClick: () => {
        navigate(routes.home.path)
      }
    }
  }

  const filterEmptyState: EmptyStateProps = {
    title: 'No trips available in this category',
    subtitle: 'Book a trip and share your link with friends and family to get started.',
    button: {
      text: 'Book Flight',
      onClick: () => {
        navigate(routes.home.path)
      }
    }
  }

  return (
    <Box sx={styles.container}>
      <PaginatedPage
        title={{ text: 'My Trips', id: 'Trips' }}
        subtitle="View your past and upcoming trips."
        initialLoading={loading}
        emptyState={emptyState}
        fetchCall={fetchFlightItineraries}
        renderListData={TripDetailsAccordion}
        horizontalFilterTabs={filterTabs}
        filterEmptyState={filterEmptyState}
      />
    </Box>
  )
}

export default Trips
