import logger from '../../functions/Logger/logger.functions'
import { SnackbarAutoHideDuration } from './Snackbar.types'
import { ShowSnackbarProps, SnackbarContextProps } from './context/Snackbar.context.types'

let snackbarContext: SnackbarContextProps | null = null

export const setSnackbarContext = (context: SnackbarContextProps) => {
  snackbarContext = context
}

export const showSnackbar = (props: ShowSnackbarProps) => {
  if (snackbarContext) {
    snackbarContext.showSnackbar({
      ...props,
      autoHideDuration: props.autoHideDuration || SnackbarAutoHideDuration.MEDIUM
    })
  } else {
    logger.logError('Snackbar context is not set', 'showSnackbar', 'Snackbar', {
      message: props.message,
      severity: props.severity
    })
  }
}

const Snackbar = {
  showSnackbar
}

export default Snackbar
