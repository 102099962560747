import { Step } from '../../shared/components/Stepper/Stepper.types'

export const CART_STORAGE_KEY = 'cart_data'
export const FLIGHT_ITINERARY_ID_STORAGE_KEY = 'flight_itinerary_id'
export const REDIRECT_TO_CART_DELIVERY_KEY = 'redirect_to_cart_delivery'
export const OFFER_REQUEST_SUBMISSION_STORAGE_KEY = 'offer_request_submission'

export const steps: Step[] = [
  { label: 'Select Items' },
  { label: 'Delivery Details' },
  { label: 'Payment Details' },
  { label: 'Order Submitted' }
]
