import React, { useState } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, StepLabel as MuiStepLabel, Step, Stepper, Typography } from '@mui/material'

import Accordion from '../../../../../shared/components/Accordion/Accordion.component'
import TripLocationsWidget from '../../../../../shared/components/TripLocationsWidget/TripLocationsWidget.component'
import StepLabel from '../../../../Trips/components/StepLabel/StepLabel.component'

import { AccordionFooterProps } from '../../../../../shared/components/Accordion/Accordion.types'
import date, { dateTimeFormats } from '../../../../../shared/functions/Date/date.functions'
import { joinStringWithBullet, joinStringWithDash } from '../../../../../shared/functions/String/string.functions'
import styles from './FlightOffer.styles'
import { FlightOfferProps } from './FlightOffer.types'

const FlightOffer: React.FC<FlightOfferProps> = (props) => {
  const {
    duration,
    stopCount,
    airlineLogo,
    originAirportName,
    destinationAirportName,
    departureTime,
    arrivalTime,
    cost,
    travelClass,
    onSelect,
    isLoading,
    offer,
    hideSelect,
    expanded: expandedProp,
    onExpand,
    mode = 'search-result',
    flightNumber
  } = props

  const [internalExpanded, setInternalExpanded] = useState(false)
  const expanded = expandedProp ?? internalExpanded

  const handleAccordionExpansion = (isExpanded: boolean) => {
    if (onExpand) {
      onExpand(isExpanded)
    } else {
      setInternalExpanded(isExpanded)
    }
  }

  const getAccordionHeader = () => {
    return {
      mainContent: (
        <Box sx={styles.cardTop}>
          <Box sx={styles.stopDurationContainer}>
            <Box sx={styles.stopCount}>
              <Typography variant="body2" sx={styles.stopText}>
                {stopCount === 0 ? 'Non Stop' : `${stopCount} Stop${stopCount > 1 ? 's' : ''}`}
              </Typography>
            </Box>

            <Typography variant="body2" sx={styles.duration}>
              {duration}
            </Typography>
          </Box>

          <TripLocationsWidget
            airlineLogo={airlineLogo}
            origin={{ airportIataCode: originAirportName }}
            destination={{ airportIataCode: destinationAirportName }}
            departureTime={departureTime}
            arrivalTime={arrivalTime}
          />
        </Box>
      )
    }
  }

  const renderDetails = () => {
    const slice = offer.slices[0]
    const segment = slice.segments[0]

    const steps = [
      {
        label: `${date(segment.departure.at).format(`${dateTimeFormats.date.long} ${dateTimeFormats.time.short}`)}`,
        description: `Depart from ${segment.origin.name} (${segment.origin.iata_code})`
      },
      {
        label: `${date(segment.arrival.at).format(`${dateTimeFormats.date.long} ${dateTimeFormats.time.short}`)}`,
        description: `Arrive at ${segment.destination.name} (${segment.destination.iata_code})`
      }
    ]

    const additionalDetails = [
      {
        label: 'Type',
        description: offer.travelClass || '-'
      },
      {
        label: 'Airline',
        description: offer.airline || '-'
      },
      {
        label: 'Flight Number',
        description: segment.flightNumber || '-'
      },
      {
        label: 'Distance',
        description: segment.distance ? `${Math.round(parseFloat(segment.distance))} km` : '-'
      }
    ]

    const flightLegSummary = () => {
      const getDateSummary = () => {
        const departureDate = date(segment.departure.at).format(dateTimeFormats.date.medium)
        const arrivalDate = date(segment.arrival.at).format(dateTimeFormats.date.medium)

        if (departureDate === arrivalDate) {
          return joinStringWithBullet([
            `${date(segment.departure.at).format(dateTimeFormats.date.long)}`,
            joinStringWithDash([
              `${date(segment.departure.at).format(dateTimeFormats.time.short)}`,
              `${date(segment.arrival.at).format(dateTimeFormats.time.short)}`
            ])
          ])
        } else {
          const departureTime = joinStringWithBullet([
            `${date(segment.departure.at).format(dateTimeFormats.date.long)}`,
            `${date(segment.departure.at).format(dateTimeFormats.time.short)}`
          ])

          const arrivalTime = joinStringWithBullet([
            `${date(segment.arrival.at).format(dateTimeFormats.date.long)}`,
            `${date(segment.arrival.at).format(dateTimeFormats.time.short)}`
          ])

          return joinStringWithDash([departureTime, arrivalTime])
        }
      }

      return (
        <Box sx={styles.legSummaryContainer}>
          <Box sx={styles.airlineLogoContainer}>
            <img src={offer.airlineLogo} alt={offer.airline} style={styles.airlineLogo} />
          </Box>

          <Box sx={styles.legSummaryContentContainer}>
            <Box sx={styles.legSummaryContent}>
              <Typography sx={styles.legSummaryContentTitleText}>{getDateSummary()}</Typography>
              <Typography sx={styles.legSummaryContentSubtitleText}>{offer.airline}</Typography>
            </Box>

            <Box sx={styles.legSummaryContent}>
              <Typography sx={styles.legSummaryContentTitleText}>{segment.duration}</Typography>

              <Typography sx={styles.legSummaryContentSubtitleText}>
                {`${segment.origin.iata_code} → ${segment.destination.iata_code}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      )
    }

    const flightLegSteps = () => {
      return (
        <Box sx={styles.legDetails}>
          <Stepper activeStep={-1} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.label}>
                <MuiStepLabel
                  StepIconComponent={StepLabel}
                  optional={<Typography sx={styles.legStepDescriptionText}>{step.description}</Typography>}
                >
                  <Typography sx={styles.legStepLabelText}>{step.label}</Typography>
                </MuiStepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )
    }

    const renderAdditionalFlightDetails = () => {
      return (
        <Box sx={styles.additionalDetailsContainer}>
          {additionalDetails.map((detail) => (
            <Box key={detail.label} sx={styles.additionalDetailContainer}>
              <Typography sx={styles.additionalDetailLabelText}>{detail.label}</Typography>
              <Typography sx={styles.additionalDetailDescriptionText}>{detail.description}</Typography>
            </Box>
          ))}
        </Box>
      )
    }

    return (
      <Box sx={styles.legDetailsContainer}>
        {flightLegSummary()}
        {flightLegSteps()}
        {renderAdditionalFlightDetails()}
      </Box>
    )
  }

  const renderFooter = () => {
    const accordionFooterButtons: unknown[] = [
      {
        text: 'Details',
        icon: (
          <ExpandMore
            style={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          />
        ),
        iconPosition: 'end',
        onClick: () => handleAccordionExpansion(!expanded),
        expandAccordion: true,
        buttonType: 'tertiary'
      },
      !hideSelect &&
        onSelect && {
          text: 'Select',
          buttonType: 'primary',
          onClick: onSelect,
          loading: isLoading
        }
    ].filter(Boolean)

    const renderMainContent = () => {
      if (mode === 'summary') {
        return (
          <Box sx={styles.durationContainer}>
            <Typography variant="h6">{duration}</Typography>
            <Typography variant="body2">Flight {flightNumber || offer.slices[0].segments[0].flightNumber}</Typography>
          </Box>
        )
      }

      return (
        <Box sx={styles.priceInfo}>
          <Typography variant="h6" sx={styles.price}>
            ${cost}
          </Typography>

          <Typography variant="body2" sx={styles.cabinClass}>
            {travelClass}
          </Typography>
        </Box>
      )
    }

    return {
      mainContent: renderMainContent(),
      buttons: accordionFooterButtons
    }
  }

  return (
    <Accordion
      header={getAccordionHeader()}
      body={renderDetails()}
      footer={renderFooter() as AccordionFooterProps}
      handleExpansion={handleAccordionExpansion}
      isExpanded={expanded}
      noExpandBackgroundColor
      noExpandIcon
    />
  )
}

export default FlightOffer
